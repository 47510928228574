
.contact_us_field {
    width: 35rem;
}

.op_review_card {
    max-width: 50rem;
    display: flex;
    flex-direction: row;
    margin: 3rem 0;
    align-items: flex-start;
}

.op_review_card_image {
    margin: 2rem;
}

.op_review_card_review_section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.op_review_card_review_section_buttons > button{
    margin: .6rem;
}

.g_mauto {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .op_review_card_image {
        margin: .4rem;
    }
    .contact_us_field {
        width: 90vw;
    }
}