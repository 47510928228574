.approval-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
    width: auto !important;
    .approval-table {
        border: 1px solid #e0e0e0;
    }
    .MuiTableCell-head {
        font-size: 16px;
        font-weight: 600;
    }
}

.confirmation_modal {
    max-width: 30rem;
    margin: 6rem auto 0 auto;
}
.rejection_reason_text_area {
    border: 1px solid grey;
    width: 100%;
    min-height: 200px;
}

.confirmation_modal_root {
    background-color: white;
    padding: 1rem;
    text-align: center;
    border-radius: 1rem;
}

.confirmation_modal_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1rem;
}

.lbl_rejection {
    text-align: start;
}
