@import 'components/Statement/Statement.scss';
@import 'components/NewsFeed/NewsFeed.scss';
@import 'components/ProjectsList//ProjectsList.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.app-wa-wrapper {
    min-height: 98vh;
}

.app-wa {
    display: flex;
    flex-direction: column;
    position: static;

    &__header {
        height: 80px;
        // margin: 1rem;
        background: white;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
        // width: 70%;
    }

    &__main {
        // margin-top: 100px;
        flex: 1;
        background-color: white;
        color: black;
        // margin-bottom: 50px;
    }

    &__footer {
        // height: 116px;
        // width: 70%;
        // margin: auto;
    }
}

.header_container {
    background-color: #f3eee2;
}

.ref-cont {
    margin-bottom: 12px;
    border-bottom: solid lightgray 2px;
}

.statement {
    width: 100%;
    min-height: 200px;
}

.g_whitetext {
    color: white;
}

.g_profile_banner {
    background-color: #135279;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    // max-width: 55rem;
    height: 10rem;
    margin: auto;
    color: white;
}

.g_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.g_row_sb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.g_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.g_tspacer1 {
    margin-top: 1rem;
}

.g_tspacer2 {
    margin-top: 2rem;
}

.g_tspacer3 {
    margin-top: 3rem;
}

.g_tspacer4 {
    margin-top: 4rem;
}

.g_bspacer1 {
    margin-bottom: 1rem;
}

.g_bspacer2 {
    margin-bottom: 2rem;
}

.g_bspacer3 {
    margin-bottom: 3rem;
}

.g_bspacer4 {
    margin-bottom: 4rem;
}

.g_w1 {
    width: 1rem;
}

.g_w2 {
    width: 2rem;
}

.g_w4 {
    width: 4rem;
}

.g_w8 {
    width: 8rem;
}

.g_w12 {
    width: 12rem;
}

.g_w16 {
    width: 16rem;
}

.g_w20 {
    width: 20rem;
}

.g_w30 {
    width: 30rem;
}

.g_w35 {
    width: 35rem;
}

.g_wfull {
    width: 100%;
}

.g_mr1 {
    margin-right: 1rem;
}

.g_mr2 {
    margin-right: 2rem;
}

.g_mr4 {
    margin-right: 4rem;
}

.g_mr8 {
    margin-right: 8rem;
}

.g_mt1 {
    margin-top: 1rem;
}

.g_mt2 {
    margin-top: 2rem;
}

.g_mt4 {
    margin-top: 4rem;
}

.g_mt8 {
    margin-top: 8rem;
}

.g_mb1 {
    margin-bottom: 1rem;
}

.g_mb2 {
    margin-bottom: 2rem;
}

.g_mb4 {
    margin-bottom: 4rem;
}

.g_mb8 {
    margin-bottom: 8rem;
}

.g_mauto {
    margin: auto;
}

.g_pb1 {
    padding-bottom: 1rem;
}

.g_pb2 {
    padding-bottom: 2rem;
}

.g_pb4 {
    padding-bottom: 4rem;
}

.g_pb8 {
    padding-bottom: 8rem;
}

.g_whitebg {
    background-color: white;
}

.g_primary_btn {
    color: white !important;
}
.bread-crumbs {
    height: auto;
    color: black;
    padding: 5px 30px;
    cursor: pointer;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    background: lightgrey;
}
.modal {
    display: block; /* Hidden by default */
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    //top: calc(100vh - 200px);
    width: 100%; /* Full width */
    height: 40%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #135279;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    font-weight: bold;
    color: white;
}

.edit-organization-container {
    .MuiFormLabel-root {
        color: whitesmoke;
    }
    .css-26l3qy-menu {
        z-index: 99999 !important;
        .css-1n7v3ny-option:hover {
            background-color: gray;
        }
        .css-yt9ioa-option:hover {
            background-color: gray;
        }
        .css-yt9ioa-option {
            color: black;
        }
    }
}
.edit-volunteer-container {
    width: 50%;
    .css-26l3qy-menu {
        z-index: 99999 !important;
        .css-1n7v3ny-option:hover {
            background-color: gray;
        }
        .css-yt9ioa-option:hover {
            background-color: gray;
        }
        .css-yt9ioa-option {
            color: black;
        }
    }
}
