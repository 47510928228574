.errormessage {
    color: red;
}
.form-field {
    border-radius: 4px;
    background-color: white;
    width: 45%;
}

.width-auto {
    width: auto;
}

.login {
    color: white;
}
.form-control {
    width: 45%;
    margin-left: 8px;
}
.form-control-label {
    margin-left: 8px;
}
.form-control-select {
    width: 100%;
    margin-left: 8px;
}
.root {
    background: white;
    padding: 45px;
    & .MuiTextField-root {
        margin: 10px;
        width: '25ch';
    }
    h3 {
        text-align: center;
    }
}
.gender-group {
    display: flex;
    flex-direction: row !important;
    margin-left: 16px;
}

// .cta-container {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//}

.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
    margin: auto;
}

.full-width {
    width: 100% !important;
}

.column {
    width: 47.5%;
    display: flex;
    flex-direction: column;
}

.row_centered {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: auto;
}

.ref-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 400px;
    margin: auto;
}

.form-footer {
    margin: 30px auto;
    max-width: 800px;
}

.info_text {
    text-align: justify;
    font-size: 0.9rem;
    color: gray;
}
