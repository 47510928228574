.container {
    position: relative;
    display: inline-block;

    .image-container {
        position: relative;

        img {
            display: block;
            // width: 100%;
            //height: auto;
        }
    }

    .input-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        input {
            padding: 10px;
        }
    }
}

.heroText {
    font-size: 48px;
    font-weight: bold;
    margin: 15px 0;
    color: #613902;
}

.subtitle {
    font-size: 24px;
    margin: 15px 0;
    color: #586632;
}

.input-container {
    padding-top: 10px;
}

.input-container .MuiBox-root, .input-container .makeStyles-search-3 {
    background: #f7f7f7;
    width: 100%;
    padding: 1px;
}

.aboutNvest {
    background-color: #F3EEE2;
}

.heroLeft {
    padding-right: 40px;
}

.heroContainer {
    padding: 50px 0;
}

.heroImg {
    width: 100%;
}

.trademark {
    font-size: 0.5em;
}

@media only screen and (max-width: 600px) {
    .input-container {
        width: 100%;
        padding: 2rem;
    }
    .heroLeft {
        padding-right: 0;
        order: 2;
    }
    .heroTextWrap {
        text-align: center;
    }
}
