.footer_comp {
    //max-width: 65rem;
    margin: 2rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.footer_comp_links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
}

.footer_comp_links_sec {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.footer_comp_links_sec > a {
    margin: 0.4rem 0;
}

.footer_links {
    text-decoration: none;
    color: black;
}

.footer_links_main {
    text-decoration: none;
    color: #CB5F25;
    font-weight: 700;
    margin: 0.4rem 0;
    padding: 0;
}

.footer_comp_logo {
    display: block;
}

.footer_comp_logo > img {
    width: 200px;
}

.footer_comp_actions {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.footer_comp_donate_btn {
    margin: 1rem;
}

.footer_comp_donate_text {
    color: black;
    font-size: 0.8rem;
    text-align: center;
}

.footer_donation {
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .footer_comp {
        flex-direction: column-reverse;
    }
    .footer_comp_logo {
        display: none;
    }
    .footer_comp_links_sec {
        margin: 0 0.2rem;
    }
}
