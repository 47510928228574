// Variables (Optional, for easier theme management)
$orange-color: #CB5F25;
$light-orange-color: #f4a87f;
$dark-orange-color: #CB5F25;
$white-color: #FFFFFF;

// Remove Calendar Border and Shadows
.react-datepicker,
.react-datepicker__month-container {
  border: none;
  box-shadow: none;
  background-color: $white-color; // White background for the calendar
}

// Calendar Popup Background
.react-datepicker-popup {
  background-color: $white-color; // Set the entire popup background to white
  color: $orange-color; // Orange text color for contrast
  padding: 0;
  border: none;

  // Calendar Header
  .react-datepicker__header {
    background-color: $orange-color !important;
    color: $white-color !important;
    padding: 12px;
    border: none;
    position: relative;
    z-index: 2;
  }

  // Weekday Names Background
  .react-datepicker__day-names {
    background-color: $orange-color !important; // White background for weekdays
    color: $white-color !important;
  }

  // Current Month Text
  .react-datepicker__current-month {
    color: $white-color;
    background-color: $orange-color; // White background for the header
    font-size: 18px;
    font-weight: bold;
  }

  // Days and Weekdays
  .react-datepicker__day-name,
  .react-datepicker__day {
    padding: 5px;
    margin: 5px;
    border-radius: 50%;

    &:hover {
      background-color: $orange-color !important;
      color: $white-color !important;
    }
  }

  // Selected Day
  .react-datepicker__day--selected {
    background-color: $dark-orange-color !important;
    color: $white-color !important;
    border-radius: 50%;
  }

  // Today's Date
  .react-datepicker__day--today {
    background-color: $light-orange-color !important; // Highlight today's date in orange
    color: $white-color !important;
    border-radius: 50%;
  }
}

// Month and Year Dropdown Containers
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  background-color: $white-color !important; // White dropdown background
  color: $orange-color !important; // Orange text color for dropdown

  .react-datepicker__month-dropdown {
    .react-datepicker__month-option--selected {
      background-color: $orange-color !important;
      color: $white-color !important;
    }

    .react-datepicker__month-option:hover {
      background-color: $orange-color !important;
      color: $white-color !important;
    }
  }

  .react-datepicker__year-dropdown {
    .react-datepicker__year-option--selected {
      background-color: $orange-color !important;
      color: $white-color !important;
    }

    .react-datepicker__year-option:hover {
      background-color: $orange-color !important;
      color: $white-color !important;
    }
  }
}

// Dialog Styles
.dialogContainer {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  z-index: 1;

  // Dialog Footer (Actions)
  .dialogFooter {
    background-color: $orange-color;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    z-index: 3;
  }
}
