.msg_container {
    min-width: 30rem;
    padding: 20px;
}

@media only screen and (max-width: 550px) {
    .msg_container {
        min-width: 10rem;
    }
}
