.cta-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000;
}
.projectpage {
    background-color: orange !important;
}

.signup_modal {
    max-width: 30rem;
    margin: 6rem auto 0 auto;
}

.signup_modal_root {
    background-color: white;
    padding: 1rem;
    text-align: center;
    border-radius: 1rem;
    border: 1px solid #5ed642;
}

.signup_modal_exit {
    width: 5rem;
    height: 2rem;
    background-color: #cf2d2d;
    color: white;
    border: none;
    border-radius: 0.2rem;
}

.signup_modal_submit {
    width: 8rem;
    height: 3rem;
    background-color: #5ed642;
    color: white;
    border: none;
    margin: 1rem;
    border-radius: 0.2rem;
}

.signup_container {
    height: 200px;
    background-image: url('../../../src/images/signup1.jpg');
    background-size: cover;
    background-position: center;
}
