.pc_content {
  max-width: 50rem;
  min-width: 30rem;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // flex: 1,
  margin: auto;
}

.pc_section {
  width: 100%;
  // justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 550px) {
  .pc_content {
    max-width: 30rem;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // flex: 1,
    padding: 20;
  }

  .pc_spaceTop {
    margin-top: 2rem;
  }
}