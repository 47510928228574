.project_card {
    width: 45rem;
    // height: 12rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project_card_image_cont {
    width: 12rem;
    height: 12rem;
    cursor: pointer;
}

.project_card_image {
    width: 12rem;
    height: 12rem;
    border-radius: 0.6rem;
    background-color: white;
}

.project_card_description {
    width: 22rem;
    cursor: pointer;
}

.project_card_map {
    width: 12rem;
    height: 12rem;
    border-radius: 0.6rem;
    background-color: gray;
}

.project_card_location {
    display: flex;
    flex-direction: row;
}

.two_shaded_icon {
    filter: invert(35%) sepia(0%) saturate(3337%) hue-rotate(316deg)
        brightness(104%) contrast(77%);
}

.action_container {
    padding: 10px;
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-around;
}
.project_title_desc_loc {
    padding: 0 10px 0px 10px;
    width: 200px;
    word-break: break-word;
}
