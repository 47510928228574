.hw_organization_list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.hw_organization_list_home_page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.fc_image_overlay {
    background: rgba(250, 250,250, 0.9);
}
