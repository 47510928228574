.searchTag {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -130%);
}

.hwv_body {
    padding-bottom: 2rem;
}

.hwv_root {
    display: flex;
    flex-direction: column;
}

.hwv_typeselector {
    margin-left: 1rem;
}

.hw_process {
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .hw_process {
        flex-direction: column;
    }
}

.hw_process_section {
    flex: 1;
    text-align: center;
    border: 0.1rem lightgray solid;
    padding: 1rem;
}

.hw_process_section_title {
    margin-bottom: 0.6rem;
}

.hw_divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
}

.hw_divider_line {
    flex: 1;
    outline: 0.1rem gray solid;
    height: 0;
    padding: 0;
}

.hw_divider_text {
    margin: 0 1rem;
}

.hw_causes_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.hw_causes_section_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.6rem 0.2rem;
    min-width: 18rem;
}

.hw_selected_button {
    width: 8rem;
    margin: 0.6rem 0.8rem 2.2rem 0.8rem;
}

.hw_email_input {
    width: 14rem;
    margin: 0.6rem 0.8rem 2.2rem 0.8rem;
}

.hw_other_button {
    margin: 0.4rem 0.6rem 2rem 0.6rem;
    width: 8rem;
    border: solid #ff9e54 0.2rem;
    border-radius: 0.4rem;
}

.hw_searcharea {
    margin: 2rem;
}

.hw_searcharea_items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

@media only screen and (max-width: 600px) {
    .hw_searcharea_items {
        flex-direction: column;
    }
}

.hw_searcharea_input {
    width: 18rem;
}

.home_numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.home_numbers_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 2rem;
}

.home_profile_banner {
    background-size: cover;
    background-position: 50% 50%;
    background-image: url('../../images/hero_img.jpg');
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    // max-width: 90rem;
    height: 35rem;
    margin: auto;
    position: relative;
    width: 100%;
}

.image_container {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.65);
}

.hw_sc_suggestion em {
    font-weight: 600;
    font-style: normal;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 42px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 240px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 5px;
}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
    background-color: #0c7eaf;
    color: #fff;
}

.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.dancounsell {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
}

.ladylexy {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
}

.mtnmissy {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
}

.steveodom {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
}

.name {
    margin-left: 68px;
    line-height: 45px;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}
