.founderImgWrap {
    display: flex;
    justify-content: center;
}
.teamImg {
    width: 400px !important;
    border-radius: 0.6rem;
}
.has-text {
    margin-left: 40%;
}
.about_root {
    display: flex;
    flex-direction: column;
}
.logo1 {
    background-color: #135279;
    margin: 0 auto;
    height: 200px;
    margin-bottom: 30px;
}
img {
    width: 300px;
}
hr {
    background-color: black;
    height: 1px;
    width: 70%;
}
// .ourvision {
//   margin-bottom: 50px !important;
// }

.about_our_team_sec {
    display: flex;
    flex-direction: row;
    max-width: 70rem;
    justify-content: center;
    margin: 3rem auto;
}

.about_learn_more_sec {
    display: flex;
    flex-direction: row;
    max-width: 70rem;
    justify-content: center;
    margin: 3rem auto;
}

.about_our_team_part {
    width: 100%;
}
.about_content {
    border: lightgray solid 0.1rem;
    padding: 4rem 3rem;

    .g_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.about_vision {
    max-width: 45rem;
    margin: auto;
    padding: 1rem;
}

.about_our_team_part h5 {
    margin-bottom: 1rem;
}
.about_our_team_part p {
    margin-bottom: 1rem;
}

.about_image_logo {
    background-image: url(../../images/about_us_1.jpg);
    background-size: cover;
    background-color: #135279;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.founders_story_img {
    background-image: url(../../images/founder_img_sm.jpg);
    background-position-x: center;
    background-size: cover;
    background-color: #135279;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.about_volunteer_types {
    display: flex;
    flex-direction: row;
    max-width: 45rem;
    margin: auto;
}

.about_volunteer_types_sec {
    flex: 1;
    text-align: center;
    margin: 0rem 4rem;
}

@media only screen and (max-width: 600px) {
    .about_our_team_sec {
        flex-direction: column-reverse;
    }
    .about_learn_more_sec {
        flex-direction: column;
    }
    .about_image_logo {
        padding: 4rem 0;
    }
    .about_our_team_part {
        width: 100%;
        // min-height: 12rem;
    }
    .about_volunteer_types {
        flex-direction: column;
    }
    .about_volunteer_types_sec {
        margin: 1rem 1rem;
    }
    .founders_story_img {
        height: 300px;
    }
    .teamImg {
        width: 300px !important;
    }
}
