.page_bg {
    background-color: #fff;
    height: 100%;
}

.volunteer_header {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #595827;
    padding-bottom: 45px;
}
.volunteer_header h4 {
    text-align: center;
    font-size: 2.215rem;
    font-weight: 400;
    line-height: 1.235;
    padding: 10px;
    margin: 0;
}

.profile_pic {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    bottom: 45px;
}

.profile_pic img {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.volunteer_page_body {
    background-color: #fff;
    margin: auto;
    display: flex;
    max-width: 55rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
}
.contact_Btn {
    margin: 0 auto;
    margin-bottom: 25px;
}
